var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.isVerified && _vm.uuid)?[_c('CheckoutApprove',{attrs:{"uuid":_vm.uuid,"details":_vm.orderData}})]:_vm._e(),[_c('Dialog',{attrs:{"dialog":_vm.verificationDialog,"dialog-width":800},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Verification ")]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"verificationForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.verifyUser.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('TextInput',{class:{
									required: !_vm.verificationCode,
								},attrs:{"hide-details":"","id":`verificationCode`,"placeholder":"Verification Code","rules":[
									_vm.vrules.required(_vm.verificationCode, 'Security Code'),
									_vm.vrules.minLength(_vm.verificationCode, 'Security Code', 4),
									_vm.vrules.maxLength(_vm.verificationCode, 'Security Code', 10),
								],"disabled":_vm.pageLoading,"loading":_vm.pageLoading},model:{value:(_vm.verificationCode),callback:function ($$v) {_vm.verificationCode=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"verificationCode"}})],1)])])]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button white--text",attrs:{"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan"},on:{"click":_vm.verifyUser}},[_vm._v(" Submit ")])]},proxy:true}])})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./CheckoutApproveMail.vue?vue&type=template&id=c29705e4"
import script from "./CheckoutApproveMail.vue?vue&type=script&lang=js"
export * from "./CheckoutApproveMail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports